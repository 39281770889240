import {
  ApiRoute,
  AssetCareError,
  ErrorOrigin,
  NotifyDeviceStatusesChanged,
} from '@/@types';
import * as signalR from '@microsoft/signalr';
import { ReactNode, createContext, useEffect, useState } from 'react';

type SignalRProviderType = {
  message: NotifyDeviceStatusesChanged | null;
  connection: signalR.HubConnection | null;
  error: Error | null;
};

export const SignalRContext = createContext<SignalRProviderType>({
  message: null,
  connection: null,
  error: null,
});

export const SignalRProvider = ({ children }: { children: ReactNode }) => {
  const [message, setMessage] = useState<NotifyDeviceStatusesChanged | null>(
    null
  );

  const [signalRConnection, setConnection] =
    useState<signalR.HubConnection | null>(null);

  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(ApiRoute.SignalR)
      .withAutomaticReconnect()
      .build();

    setConnection(connection);

    connectSignalR(connection);

    connection.on(
      'newStatusMessage',
      (message: NotifyDeviceStatusesChanged) => {
        setMessage(message);
      }
    );

    connection.onreconnected(() => {
      setError(null);
    });

    connection.onreconnecting((error) => {
      if (!window.navigator.onLine) {
        setError(new AssetCareError('', 400, ErrorOrigin.SIGNALR));
      }
      if (error) {
        setError(new AssetCareError(error.message, 500));
      }
    });

    return () => {
      disconnectSignalR(connection);
    };
  }, []);

  const connectSignalR = (connection: signalR.HubConnection) => {
    if (!window.navigator.onLine) {
      setError(
        new AssetCareError('No internet connection.', 400, ErrorOrigin.SIGNALR)
      );
    }
    connection
      .start()
      .then(() => {
        setError(null);
      })
      .catch((err: Error) => {
        setError(new AssetCareError(err.message, 500));
      });
  };

  const disconnectSignalR = (connection: signalR.HubConnection) => {
    connection.stop().then(() => console.log('SignalR Disconnected.'));
  };

  return (
    <SignalRContext.Provider
      value={{ message: message, connection: signalRConnection, error: error }}
    >
      {children}
    </SignalRContext.Provider>
  );
};
