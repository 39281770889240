import { ApiRoute, AssetCareError, Device, ErrorOrigin } from '@/@types';
import { useSignalRContext } from '@/hooks';
import { fetchWithAuth } from '@/utils/fetchWithAuth';
import { useQuery } from '@tanstack/react-query';
import { createContext, useMemo } from 'react';

type DashboardDataProviderType = {
  data: Device[] | undefined;
  isFetching?: boolean;
  fetchStatus?: 'fetching' | 'paused' | 'idle';
  availableDeviceTypes: string[];
  lastUpdated: number;
  dashDataError: Error | null;
  signalRError: Error | null;
};

export const DashboardDataContext = createContext<DashboardDataProviderType>({
  data: undefined,
  isFetching: undefined,
  fetchStatus: 'fetching',
  availableDeviceTypes: [],
  lastUpdated: 0,
  dashDataError: null,
  signalRError: null,
});
const errorOrigin = ErrorOrigin.DASHBOARDDATA;

const fetchQuery = async () => {
  if (!window.navigator.onLine) {
    throw new AssetCareError('No internet connection.', 400, errorOrigin);
  }

  const response = await fetchWithAuth(ApiRoute.Assets);

  if (!response.ok) {
    throw new AssetCareError(
      `Data fetching failed with status: ${response.status} and status text: ${response.statusText}`,
      response.status,
      errorOrigin
    );
  }

  const apiJson: Device[] = await response.json();
  return apiJson;
};

export const DashboardDataProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { message, error: signalRError } = useSignalRContext();
  const {
    data,
    isFetching,
    error: dashDataError,
    isSuccess,
    dataUpdatedAt,
    errorUpdatedAt,
    fetchStatus,
  } = useQuery({
    queryKey: ['dashboard'],
    queryFn: fetchQuery,
  });

  const updatedData = useMemo(() => {
    if (!data) {
      return;
    }

    if (!message) {
      return data;
    }

    return data.map((device) => {
      const isDeviceUpdated = message.SerialNumber === device.serialNumber;

      if (isDeviceUpdated) {
        return {
          ...device,
          deviceStatus: message.Status,
        };
      }

      return device;
    });
  }, [data, message]);

  const lastUpdated = useMemo(() => {
    const successOrErrorTime = isSuccess ? dataUpdatedAt : errorUpdatedAt;

    if (message) {
      const messageTime = new Date(message.LastUpdated).getTime();

      return messageTime > successOrErrorTime
        ? messageTime
        : successOrErrorTime;
    }

    return successOrErrorTime;
  }, [message, isSuccess, dataUpdatedAt, errorUpdatedAt]);

  const availableDeviceTypes = Array.from(
    new Set<string>(data?.map((device) => device.deviceType))
  );

  return (
    <DashboardDataContext.Provider
      value={{
        data: updatedData,
        isFetching,
        fetchStatus,
        availableDeviceTypes,
        lastUpdated: lastUpdated,
        dashDataError,
        signalRError,
      }}
    >
      {children}
    </DashboardDataContext.Provider>
  );
};
